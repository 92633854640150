import React, { useEffect, useState } from "react";
import QBInput from "./QBInput";

let _id = 0;

const getId = () => {
  return ++_id;
};
const QueryBuilder = ({
  options,
  onQueryChange,
  defaultQuery,
  fieldOptions,
}) => {
  const [rules, setRules] = useState(
    defaultQuery || [{ id: getId(), option: "", operator: "" }]
  );

  useEffect(() => {
    if (rules.length === 0) {
      setRules([{ id: getId(), option: "", operator: "" }]);
    }
    onQueryChange(rules);
  }, [rules]);

  const changeRuleOption = (id, value) => {
    const option = options.find((option) => option.name === value);
    const oldRule = rules.find((rule) => rule.id === id);
    setRules((rules) =>
      rules.map((rule) => {
        if (rule.id === id) {
          return {
            ...rule,
            option: value,
            operators: option?.operators,
            type: option?.type,
            operator: option?.operators[0].value,
            requiresValue: option?.operators[0].requiresValue,
            value: oldRule.type !== option?.type ? "" : oldRule.value,
          };
        }
        return rule;
      })
    );
  };
  const changeRuleOperator = (id, value) => {
    const rule = rules.find((rule) => rule.id === id);
    const operator = rule?.operators.find(
      (operator) => operator.value === value
    );

    setRules((rules) =>
      rules.map((rule) => {
        if (rule.id === id) {
          return {
            ...rule,
            operator: value,
            requiresValue: operator?.requiresValue,
          };
        }
        return rule;
      })
    );
  };

  const changeRuleValue = (id, value) => {
    setRules((rules) =>
      rules.map((rule) => {
        if (rule.id === id) {
          return { ...rule, value: value };
        }
        return rule;
      })
    );
  };

  const addRule = () => {
    setRules((rules) => {
      return [...rules, { id: getId(), option: "", operator: "", value: "" }];
    });
  };

  const removeRule = (id) => {
    setRules((rules) => {
      return rules.filter((rule) => rule.id !== id);
    });
  };

  return (
    <div className="query-builder">
      <div className="rules-list">
        {rules.map((rule, index) => (
          <div className="rule-container" key={rule.id}>
            <div className="rule-filter-container">
              <select
                className="form-control"
                name={`rule-option[${rule.id}]`}
                value={rule.option}
                onChange={(e) => changeRuleOption(rule.id, e.target.value)}
              >
                <option value={""}>------</option>
                {options.map((option, index) => (
                  <option key={index} value={option.name}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="rule-operator-container">
              {rule.option && (
                <select
                  name={`rule-operator[${rule.id}]`}
                  value={rule.operator}
                  onChange={(e) => changeRuleOperator(rule.id, e.target.value)}
                >
                  {rule.operators.map((operator, index) => (
                    <option key={index} value={operator.value}>
                      {operator.label}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <QBInput
              rule={rule}
              changeRuleValue={changeRuleValue}
              fieldOptions={fieldOptions}
            />
            <div className="btn-group rule-actions">
              {index !== 0 && (
                <button
                  type="button"
                  className="button alert"
                  onClick={() => removeRule(rule.id)}
                >
                  <i className="icon icon-minus" />
                </button>
              )}
              <button
                type="button"
                className="button primary"
                onClick={addRule}
                style={{ marginLeft: 4 }}
              >
                <i className="icon icon-plus" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QueryBuilder;
