let id = 505505;

const queryBuilderService = {
  filterValid: (query) => {
    return (
      query?.filter((rule) => {
        if (!rule.option) {
          return false;
        }
        if (rule.requiresValue && (!rule.value || rule.value?.length === 0)) {
          return false;
        }
        return true;
      }) || []
    );
  },

  convertToRulesParams: (query) => {
    return {
      condition: "AND",
      rules: query.map((rule) => {
        return {
          id: rule.option,
          operator: rule.operator,
          value: rule.requiresValue
            ? queryBuilderService.parseValueByType(rule.value, rule.type)
            : undefined,
          type: rule.type === "text" ? "string" : rule.type,
        };
      }),
    };
  },

  convertFromRulesParams: (rulesParams, options) => {
    return rulesParams.map((rule) => {
      const option = options.find((opt) => opt.name === rule.id);
      const operator = option.operators.find(
        (opr) => opr.value === rule.operator
      );

      return {
        id: ++id,
        option: rule.id,
        operator: rule.operator,
        value: operator.requiresValue
          ? queryBuilderService.parseValueFromRulesParams(
              rule.value,
              option.type
            )
          : "",
        requiresValue: operator.requiresValue,
        type: option.type,
        operators: option.operators,
      };
    });
  },

  parseValueByType: (value, type) => {
    if (type === "text") {
      return value.map((val) => val.value).join(";");
    }
    if (type === "date") {
      return value;
    }
  },

  parseValueFromRulesParams: (value, type) => {
    if (type === "text") {
      return value.split(";").map((val) => ({ value: val, label: val }));
    }
    if (type === "date") {
      return value;
    }
  },
};

export default queryBuilderService;
